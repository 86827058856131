// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-acronis-js": () => import("./../../../src/pages/acronis.js" /* webpackChunkName: "component---src-pages-acronis-js" */),
  "component---src-pages-contpaqi-bancos-js": () => import("./../../../src/pages/contpaqi-bancos.js" /* webpackChunkName: "component---src-pages-contpaqi-bancos-js" */),
  "component---src-pages-contpaqi-cfdi-facturacion-en-linea-js": () => import("./../../../src/pages/contpaqi-cfdi-facturacion-en-linea.js" /* webpackChunkName: "component---src-pages-contpaqi-cfdi-facturacion-en-linea-js" */),
  "component---src-pages-contpaqi-comercial-js": () => import("./../../../src/pages/contpaqi-comercial.js" /* webpackChunkName: "component---src-pages-contpaqi-comercial-js" */),
  "component---src-pages-contpaqi-contabilidad-js": () => import("./../../../src/pages/contpaqi-contabilidad.js" /* webpackChunkName: "component---src-pages-contpaqi-contabilidad-js" */),
  "component---src-pages-contpaqi-factura-electronica-js": () => import("./../../../src/pages/contpaqi-factura-electronica.js" /* webpackChunkName: "component---src-pages-contpaqi-factura-electronica-js" */),
  "component---src-pages-contpaqi-js": () => import("./../../../src/pages/contpaqi.js" /* webpackChunkName: "component---src-pages-contpaqi-js" */),
  "component---src-pages-contpaqi-nominas-js": () => import("./../../../src/pages/contpaqi-nominas.js" /* webpackChunkName: "component---src-pages-contpaqi-nominas-js" */),
  "component---src-pages-contpaqi-produccion-js": () => import("./../../../src/pages/contpaqi-produccion.js" /* webpackChunkName: "component---src-pages-contpaqi-produccion-js" */),
  "component---src-pages-contpaqi-punto-de-venta-js": () => import("./../../../src/pages/contpaqi-punto-de-venta.js" /* webpackChunkName: "component---src-pages-contpaqi-punto-de-venta-js" */),
  "component---src-pages-contpaqi-xml-en-linea-js": () => import("./../../../src/pages/contpaqi-xml-en-linea.js" /* webpackChunkName: "component---src-pages-contpaqi-xml-en-linea-js" */),
  "component---src-pages-evalua-035-js": () => import("./../../../src/pages/evalua035.js" /* webpackChunkName: "component---src-pages-evalua-035-js" */),
  "component---src-pages-gigas-js": () => import("./../../../src/pages/gigas.js" /* webpackChunkName: "component---src-pages-gigas-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-nosotros-js": () => import("./../../../src/pages/nosotros.js" /* webpackChunkName: "component---src-pages-nosotros-js" */),
  "component---src-pages-page-2-js": () => import("./../../../src/pages/page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */),
  "component---src-pages-productos-js": () => import("./../../../src/pages/productos.js" /* webpackChunkName: "component---src-pages-productos-js" */),
  "component---src-pages-soporte-js": () => import("./../../../src/pages/soporte.js" /* webpackChunkName: "component---src-pages-soporte-js" */)
}

